import React, { useEffect } from 'react';

const DeLoginPage = () => {
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log(`${queryParams}`);
    window.location.replace(`https://account.lylli.de/password/?${queryParams}`)
  }, [isBrowser]);

  return <></>;
}

export default DeLoginPage;